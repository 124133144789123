import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted,onActivated, getCurrentInstance,nextTick} from 'vue';
import BrandUtil ,{IBrandDataObj} from './brandUtil';
export default defineComponent ({
    name: 'clientBrand',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            document.title = '车辆使用手册';
        })
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IBrandDataObj=reactive<IBrandDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                brandData:[]
            }
        })
        onActivated(()=>{
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BrandUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.initPage();
            })
        })
        const toCxPage=(brandId:string)=>{
            proxy.$router.push({path:'/clientCx',query:{brandId:brandId}});
        }
        return{
            ...toRefs(dataObj),toCxPage
        }
    }
});