import BaseBean from "@/utils/BaseBean";

export interface IBrandDataObj {
    utilInst:BrandUtil
    refMap:Map<string,any>
    pageListRef:any
    otherParams:any
}

export default class BrandUtil extends BaseBean{
    public dataObj:IBrandDataObj

    constructor(proxy:any,dataObj:IBrandDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public async initPage():Promise<any>{
        this.dataObj.otherParams.brandData=await this.utils.ToolsProviderApi.clientQueryManualCx({flag:0});
    }
}